<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-select
          v-model="form.type"
          class="mt-5"
          label="Template Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-text-field
          v-model="form.nicename"
          label="Display Name"
        />

        <translatable-input
          v-model="form.subject"
          label="Email Subject"
        />

        <translatable-textarea
          v-model="form.content"
          label="Email Content"
        />

        <p>This supports custom formatting:</p>
        <ul>
          Variables & example:

          <b>All Quota Templates:</b>
          <li>[[participant,full_name]] (email, phone...)</li>
          <li>participant: The user must have filled participant info during checkout.</li>

          <b>+ Welcome (quota_group.created / quota_topup.created)</b>
          <li>[[order,order_number]]</li>
          <li>[[product]] The product title.</li>
          <li>[[quota_group,started_at|date,format:Y-m-d]]</li>
          <li>[[quota_group,ended_at|date,format:Y-m-d]]</li>

          <b>+ All application related (confirmed, cancelled, pending, reschedule..)</b>
          <li>[[customer_name]] Participant full name.</li>
          <li>[[service]] SKU name.</li>
          <li>[[appointment_id]]</li>
          <li>[[applications,0,location]] 0 = first choice</li>
          <li>[[applications,1,service_date]]</li>
          <li>[[applications,1,service_time]]</li>
          <li>Note: pending confirmed or reschedule confirmed only has [0] one record, dont use ",1".</li>

          <b>+ application confirmed, rescheduled</b>
          <li>[[qr_code,code]]</li>
        </ul>
        <br>

        <br><br>

        <v-autocomplete
          v-model="form.merchant_id"
          placeholder="Merchant"
          :items="merchantOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
          @change="reloadProducts"
        ></v-autocomplete>

        <v-autocomplete
          v-model="form.product_id"
          placeholder="Product"
          :items="productOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslatableTextarea, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import merchantStore from '@/modules/merchant/store'
import store from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'
import useProductList from '../product-list/useProductList'

export default {
  components: { TranslatableTextarea, TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      nicename: props.resource?.nicename || '',
      type: props.resource?.type || 'quota_application.pending',
      subject: useTranslatable(props.resource, 'subject'),
      content: useTranslatable(props.resource, 'content'),
      merchant_id: props.resource ? props.resource.merchant_id : null,
      product_id: props.resource ? props.resource.product_id : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      // console.log('valid?', formElem.value.validate())
      // if (formElem.value.validate()) {
      emit('submit', form.value)

      // }
    }

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `#${merchant.id} - ${t(merchant.name)}`, value: merchant.id })))

    const { productOptions, loadProducts } = useProductList({ take: 999 })

    const reloadProducts = () => loadProducts({
      take: 999,
      merchant_id: form.value.merchant_id,
    }).then(() => {
    })

    reloadProducts()

    const typeOptions = [
      { title: 'new Quota purchased in package. e.g. AXA better she (quota_group.created)', value: 'quota_group.created' },
      { title: 'new Topup on existing quota. e.g. AXA Jet peel. (quota_topup.created)', value: 'quota_topup.created' },
      { title: 'quota_application.pending_confirm', value: 'quota_application.pending_confirm' },
      { title: 'quota_application.confirmed', value: 'quota_application.confirmed' },
      { title: 'quota_application.pending_reschedule', value: 'quota_application.pending_reschedule' },
      { title: 'quota_application.rescheduled', value: 'quota_application.rescheduled' },
      { title: 'quota_application.cancelled', value: 'quota_application.cancelled' },
    ]

    return {
      form,
      formElem,

      validate,
      required,

      reloadProducts,
      merchantOptions,
      productOptions,
      natureOptions: store.state.natureOptions,
      typeOptions,
    }
  },
}
</script>
